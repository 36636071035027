import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { useNavigate } from 'react-router-dom';
import { UploadService } from '../../../scripts/UploadService';
import { Req } from '../../../scripts/Request';
import ReactGA from 'react-ga';
import './index.css';




export function Plataformas() {
    const component_name = "admin/plataformas";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [newPlatform, setNewPlatform] = useState({img: "", title:"", subtitle:"", url: "", tipo: "1"});
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();



     //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadPlataformas();
    }
    //#endregion Init


    //#region Loaders
    const LoadPlataformas = async(tipo = "") => {
        let result = await Req("api/Plataformas/List/" + (tipo === "" ? newPlatform.tipo : tipo), "GET", "", user.token, 0)
        .then((data) => {
            setList(data);
        })
        .catch(() => {
            window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (PLT 001)", "error");
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleGoTo = (page) => {
        navigate(page);
    }


    const _HandleChange = (event) => {
        setNewPlatform({...newPlatform, [event.target.id]: event.target.value});

        if(event.target.id === "tipo") LoadPlataformas(event.target.value);
    }


    const onImageChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            UploadImages(e);
        };
        reader.readAsDataURL(files[0]);
    }


    const UploadImages = async(event) => {
        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        upload.Data = newPlatform;

        upload.OnEnd = async(result) => {
            let arr = JSON.parse(result.img);
            c++;

            if(c === event.target.files.length) {
                setPageLoading(false);
                LoadPlataformas();
                setNewPlatform({img: "", title:"", subtitle:"", url: "", tipo: newPlatform.tipo});
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

        await upload.PopUpload("https://kslot.com.br/api/Plataformas/Upload", user.token);
    }


    const _HandleDelete = async(id) => {
        let result = await Req("api/Plataformas/Delete/"+id, "GET", "", user.token, 0)
        .then((data) => {
            window.swal("Alerta", "Plataforma apagada com sucesso", "success");    
            LoadPlataformas();
        })
        .catch(() => {
            window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (PLT 002)", "error");
        });
    }


    const PlatformSave = () => {
        if(newPlatform.title===''||newPlatform.subtitle===''||newPlatform.url===''||newPlatform.tipo==='') {
            window.swal("Alerta", "Preencha todos os campos coió!", "error");
        } else {
            hiddenFileInput.current.click();
        }
    }
    //#endregion Handlers



    return (
        <div>
            <div className="_panel _panel--left">
                <div className="_panel__navigation">
                    <div className="_swiper-wrapper">
                        <div className="_swiper-slide">
                            <div className="subnav-header closepanel"><img src="/images/icons/arrow-back.svg" alt="" title="" onClick={() => _HandleGoTo("/Admin/Home")}/></div>


                            <div className="form_spacing">
                                <h2 className="page__title">CADATRO DE PLATAFORMAS</h2>

                                <div className="fieldset">
                                    <div className="form__row">
                                        <label>Título *</label>
                                        <input type="text" id="title" name="title" value={newPlatform.title} className="form__input required" onChange={_HandleChange}/>
                                    </div>
                                    <div className="form__row">
                                        <label>Subtítulo *</label>
                                        <input type="text" id="subtitle" name="subtitle" value={newPlatform.subtitle} className="form__input required" onChange={_HandleChange}/>
                                    </div>
                                    <div className="form__row">
                                        <label>Link do Convite *</label>
                                        <input type="text" id="url" name="subtitle" value={newPlatform.url} className="form__input required" onChange={_HandleChange}/>
                                    </div>
                                    <div className="form__row">
                                        <label>Tipo *</label>
                                        <select id="tipo" className="form__input required" value={newPlatform.tipo} onChange={_HandleChange}>
                                            <option value="1">Pagantes do dia</option>
                                            <option value="2">Ativas para cooperação</option>
                                            <option value="3">Todas plataformas</option>
                                        </select>
                                    </div>
                                </div>

                                <button className="button button--full button--main pointer" onClick={PlatformSave} disabled={newPlatform.title===''||newPlatform.subtitle===''||newPlatform.url===''}> ENVIAR IMAGEM E SALVAR</button>
                                <input type="file" ref={hiddenFileInput} onChange={onImageChange} style={{display: 'none'}} accept=".jpg,.png"/>   
                       
           
                            </div>

                           
                           

                            {Array.isArray(list) && list.length > 0 && list.map((item, i) => (
                                 <div className="cards cards--11 plat-form_spacing" >
                                 <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
                                     <div className="card__icon">
                                         <img  className="platform_thumb" src={"/db/"+item.img} alt="" title=""/>
                                     </div>
                                     <div className="card__details">
                                         <h4 className="card__title">{item.title}</h4>
                                         <p className="card__text">{item.subtitle}</p>
                                     </div>

                                     <div className="center_thing pointer" onClick={() => _HandleDelete(item.plataforma_id)}>
                                         <img src="/images/recicle-bin.svg" alt="" title=""/>
                                     </div>
                                     
                                 </div>
                             </div>
                            ))}
                           
                           
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}