import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext';
import { InvalidatorContext } from '../../Context/InvalidatorContext';
import { Req, Request } from '../../scripts/Request';
import { WaitObject } from '../../scripts/Wait';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';


let plataformas_arr = [[],[],[]];

export default function Plataforma() {
    const component_name = "plataformas";
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const { Hub } = useContext(HubContext);
    const [plataformas, setPlataformas] = useState([[],[],[]]);
    const [presentation, setPresentation] = useState("As melhores plataformas você encontra aqui");
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        LoadPlataformas(1);
    }
    //#endregion Init


    //#region Loaders
    const LoadPlataformas = async(tipo = 1) => {
        let result = await Req("api/Plataformas/List/" + tipo, "GET", "", "", 0)
        .then((data) => {
            plataformas_arr[tipo-1] = data;

            if(tipo === 3) {
                console.log(plataformas_arr);
                setPlataformas(plataformas_arr);
                InitSwiper();
            } else {
                LoadPlataformas(tipo+1);
            }
        })
        .catch(() => {
            window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (PLM 001)", "error");
        });
    }
    //#endregion Loaders


    //#region Swiper Init
    const InitSwiper = () => {
        setTimeout( () => { 
            var simplesliders = [];
            var simpleslider = [];
            window.$('.slider-init0').each(function(index, element){
                var paginationtype = window.$(this).attr("data-paginationtype");
                var spacebetweenitems = window.$(this).data("spacebetweenitems");
                var itemsperview = window.$(this).data("itemsperview");
                window.$(this).addClass('s'+index);
                window.$(this).prev().children(".page__title-right").children(".swiper-button-next").addClass('swiper-button-next'+index);
                window.$(this).prev().children(".page__title-right").children(".swiper-button-prev").addClass('swiper-button-prev'+index);
                window.$(this).children(".swiper-pagination").addClass('swiper-pagination'+index);
                simpleslider[index] = new window.Swiper('.s'+index, {
                    direction: 'horizontal',
                    loop: true,
                    effect: 'slide',
                    slidesPerView: itemsperview,
                    spaceBetween: spacebetweenitems,
                    pagination: {
                    el: '.swiper-pagination'+index,
                    type: paginationtype,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next'+index,
                        prevEl: '.swiper-button-prev'+index
                    }
                    
                    }).autoplay.start();
                simplesliders.push(simpleslider[index]);
            });

            
            var cartsliders = [];
            var cartslider = [];
            window.$('.slider-init-swipe').each(function(index, element){
                
                window.$(this).addClass('c'+index);
                cartslider[index] = new window.Swiper('.c'+index, {
                    direction: 'horizontal',
                    effect: 'slide',
                    slidesPerView: 'auto',
                    spaceBetween: 0
                    
                    });
                cartsliders.push(cartslider[index]);
            });
            
            window.$(".cart__item-more").on('click', function(e) {
                var swipeitem = window.$(this).data("swipe-item");
                cartslider[swipeitem].slideNext();
            });
        }, 100);
        
    }
    //#endregion Swiper Init


    //#region Handlers
    const _HandleGoTo = (page) => {
        navigate(page);
    }
    //#endregion Handlers




    return (
        <>
            <div className="body-overlay"></div>

            <div className="page page--main" data-page="main">
                

                <div className="page__content page__content--full page__content--with-bottom-nav">
                    <div className="account-info">
                        <div className="img-thumb">
                            <div className="round-img">
                                <img className="" src={"/db/logo_camila.jpg?" + (Invalidator['profile_image'])} />
                            </div>
                        </div>
			            <div className="account-text"><div>{presentation}</div></div>
                        <svg className="account-info__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <path d="M0,0 Q50,201 100,0 L100,100 0,100 Z" fill="#0f0638"/>
                        </svg>
                    </div>

                    <br />
                    <br />
                    <div className="page-inner">
                        <div className="page__title-bar">
                            <h3 className="title_platform">&nbsp; PLATAFORMAS PAGANTES DO DIA</h3>
                            
                            <div className="page__title-right">
                                <div className="swiper-button-prev slider-portfolio__prev"></div>
                                <div className="swiper-button-next slider-portfolio__next"></div>
                            </div>
                        </div>

                        <div className="swiper-container slider-portfolio slider-portfolio--round-corners slider-init0 mb-40" data-paginationtype="progressbar" data-spacebetweenitems="10" data-itemsperview="auto">
                            <div className="swiper-wrapper">

                                { Array.isArray(plataformas[0]) && plataformas[0].length > 0 && plataformas[0].map((item, i) => (
                                    <div key={'plat'+i} className="swiper-slide slider-portfolio__slide slider-portfolio__slide--1h" data-swiper-autoplay="2000">
                                        <div className="slider-portfolio__caption caption">
                                            <div className="caption__content">
                                                <div className="platform">
                                                    <img className="plat_img" src={"/db/"+item.img} />
                                                </div>
                                                
                                                <div className="platform">
                                                    <div className="platform_text">
                                                        <div className="platform_title">{item.title}</div>
                                                        <div className="platform_subtitle">{item.subtitle}</div>
                                                        <div className="btn-invite"><a className="button button--full button--main" href={item.url} target="_blank">Clique Aqui</a></div>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                ))}
                            
                            </div>
                            <div className="swiper-pagination slider-portfolio__pagination"></div>
                        </div>


                        <br />
             
                        <div className="page__title-bar">
                            <h3 className="title_platform">&nbsp; PLATAFORMAS ATIVAS PARA COOPERAÇÃO</h3>
                            
                            <div className="page__title-right">
                                <div className="swiper-button-prev slider-portfolio__prev"></div>
                                <div className="swiper-button-next slider-portfolio__next"></div>
                            </div>
                        </div>

                        <div className="swiper-container slider-portfolio slider-portfolio--round-corners slider-init0 mb-40" data-paginationtype="progressbar" data-spacebetweenitems="10" data-itemsperview="auto">
                            <div className="swiper-wrapper">

                                { Array.isArray(plataformas[1]) && plataformas[1].length > 0 && plataformas[1].map((item, i) => (
                                    <div key={'plat'+i} className="swiper-slide slider-portfolio__slide slider-portfolio__slide--1h" data-swiper-autoplay="2300">
                                        <div className="slider-portfolio__caption caption">
                                            <div className="caption__content">
                                                <div className="platform">
                                                    <img className="plat_img" src={"/db/"+item.img} />
                                                </div>
                                                
                                                <div className="platform">
                                                    <div className="platform_text">
                                                        <div className="platform_title">{item.title}</div>
                                                        <div className="platform_subtitle">{item.subtitle}</div>
                                                        <div className="btn-invite"><a className="button button--full button--main" href={item.url} target="_blank">Clique Aqui</a></div>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                ))}
                            
                            </div>
                            <div className="swiper-pagination slider-portfolio__pagination"></div>
                        </div>

                        <br />
             
                        {/*    
                        <div className="page__title-bar">
                            <h3 className="title_platform">&nbsp; TODAS PLATAFORMAS </h3>
                            
                            <div className="page__title-right">
                                <div className="swiper-button-prev slider-portfolio__prev"></div>
                                <div className="swiper-button-next slider-portfolio__next"></div>
                            </div>
                        </div>

                        <div className="swiper-container slider-portfolio slider-portfolio--round-corners slider-init0 mb-40" data-paginationtype="progressbar" data-spacebetweenitems="10" data-itemsperview="auto">
                            <div className="swiper-wrapper">

                                { Array.isArray(plataformas[2]) && plataformas[2].length > 0 && plataformas[2].map((item, i) => (
                                    <div key={'plat'+i} className="swiper-slide slider-portfolio__slide slider-portfolio__slide--1h" data-swiper-autoplay="2600">
                                        <div className="slider-portfolio__caption caption">
                                            <div className="caption__content">
                                                <div className="platform">
                                                    <img className="plat_img" src={"/db/"+item.img} />
                                                </div>
                                                
                                                <div className="platform">
                                                    <div className="platform_text">
                                                        <div className="platform_title">{item.title}</div>
                                                        <div className="platform_subtitle">{item.subtitle}</div>
                                                        <div className="btn-invite"><a className="button button--full button--main" href={item.url} target="_blank">Clique Aqui</a></div>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                ))}
                            
                            </div>
                            <div className="swiper-pagination slider-portfolio__pagination"></div>
                             </div>*/}

                        <br /> 
                        <br /> 
                        <br /> 
                        <br /> 
                        <br />   
                        <br /> 
                        <br />               
                        
                    </div>
                </div>    
            </div>
        </>
    )
}