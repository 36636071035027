import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { GetServer } from './scripts/Loadbalancer';
import { Context } from './Context/AuthContext';

import Login  from './components/Login/Login';
import Plataforma from './components/Plataforma'
import PlataformaHS from './components/PlataformaHS'
import Layout from './Layout.js';
import Home from './components/Admin/Home';
import Config from './components/Admin/Config';
import { Plataformas as AdminPlataformas } from './components/Admin/Plataformas';
import Plataformas from './components/Plataformas';
import Porcentagens from './components/Porcentagens';
import Links from './components/Admin/Links';
import Apostas from './components/Apostas';


export default function RouteManager() {
  const location = useLocation();
  const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
  const navigate = useNavigate();
  const loadPrestineRef = useRef(false);


  useEffect(() => {
    let wait = setTimeout(() => {
      if(!loadPrestineRef.current) {
          loadPrestineRef.current = true;
          
      }
    }, 200);

  }, [])


  const ProtectedRoute = ({ children }) => {
    const { loading, authenticated } = useContext(Context);
    if (!authenticated && !loading && !isLogin) {
        navigate("/login");
        //return children;
    } else {
        return children;
    }

  };


  return (
    <Suspense fallback={<div>Carregando...</div>}>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} /> 
          <Route exact path="/Plataforma" element={<Plataforma />} /> 
          <Route exact path="/Plataforma/:Name/:Hash" element={<PlataformaHS />} /> 
          <Route exact path="/Plataforma/:Hash" element={<PlataformaHS />} /> 
          <Route exact path="/PlataformaHS" element={<PlataformaHS />} /> 
          <Route exact path="/PlataformaHS/:Name/:Hash" element={<PlataformaHS />} /> 
          <Route exact path="/PlataformaHS/:Hash" element={<PlataformaHS />} /> 
          <Route exact path="/Porcentagens/:Name/:Hash" element={<Porcentagens />} /> 
          <Route exact path="/Porcentagens/:Hash" element={<Porcentagens />} /> 
          <Route exact path="/Apostas" element={<Apostas />} />
          <Route exact path="/Plataformas" element={<Plataformas />} /> 
          
          <Route exact path="/Admin/Home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route exact path="/Admin/Config" element={<ProtectedRoute><Config /></ProtectedRoute>} />
          <Route exact path="/Admin/Plataformas" element={<ProtectedRoute><AdminPlataformas /></ProtectedRoute>} />
          <Route exact path="/Admin/Links" element={<ProtectedRoute><Links /></ProtectedRoute>} />
        </Routes>
      </Layout>
    </Suspense>
  )
}