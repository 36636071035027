import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext';
import { useNavigate } from 'react-router-dom';
import { Req } from '../../../scripts/Request';
import { Uuid } from '../../../scripts/StringUtils';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './index.css';



export default function Links() {
    const component_name = "admin/plataformas";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [newLink, setNewLink] = useState({nome: Uuid(), expires: moment().add('days', 1).format("YYYY-MM-DD HH:mm"), qtd: 1});
    const [link, setLink] = useState("");
    const [links, setLinks] = useState([]);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();



     //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        
    }
    //#endregion Init



    //#region Handlers
    const _HandleGoTo = (page) => {
        navigate(page);
    }


    const _HandleChange = (event) => {
        setNewLink({...newLink, [event.target.id]: event.target.value});
    }


    const RequestLink = async() => {
        let _links = [];
        if(newLink.qtd>0) {
            for(let i=0; i<newLink.qtd; i++) {
                let result = await Req("api/Plataformas/GetHash?nome=" + Uuid() + "&expires=" + moment(newLink.expires).format("YYYY-MM-DD HH:mm:ss"), "GET", "", user.token, 0)
                .then((data) => {
                    _links.push("https://kslot.com.br/Plataforma/" + data.replaceAll("=", ""));
                    
                })
                .catch(() => {
                    window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (LNK 001)", "error");
                });
            }

            setNewLink({nome: Uuid(), expires: moment().add('days', 1).format("YYYY-MM-DD HH:mm"), qtd: 1});
            console.log(_links);
            setLinks(_links);
        }


        /*
        let result = await Req("api/Plataformas/GetHash?nome=" + newLink.nome + "&expires=" + moment(newLink.expires).format("YYYY-MM-DD HH:mm:ss"), "GET", "", user.token, 0)
        .then((data) => {
            setLink("https://kslot.com.br/Plataforma/" + data.replaceAll("=", ""));
            setNewLink({nome: Uuid(), expires: moment().add('days', 1).format("YYYY-MM-DD HH:mm")});
        })
        .catch(() => {
            window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (LNK 001)", "error");
        });*/
    }


    const ToClipboard = (txt) => {
        navigator.clipboard.writeText(txt)
        .then(() => {
            window.swal("Sucesso", "Link Copiado para área de transferência", "success");
            setLink("");
            setLinks([]);
        })
        .catch((error) => {
            
        });
    }
    //#endregion Handlers

    return (
        <div>
        <div className="_panel _panel--left">
            <div className="_panel__navigation">
                <div className="_swiper-wrapper">
                    <div className="_swiper-slide">
                        <div className="subnav-header closepanel"><img src="/images/icons/arrow-back.svg" alt="" title="" onClick={() => _HandleGoTo("/Admin/Home")}/></div>

                        <div className="form_spacing">
                            <h2 className="page__title">GERAR LINKS ÚNICOS TEMPORÁRIOS</h2>

                            <div className="fieldset">
                                <div className="form__row">
                                    <label>Nome</label>
                                    <input type="text" id="nome" name="nome" value={newLink.nome} className="form__input required" onChange={_HandleChange}/>
                                </div>
                                <div className="form__row">
                                    <label>Data de expiração</label>
                                    <input type="datetime-local" id="expires" name="expires" value={newLink.expires} className="form__input required" onChange={_HandleChange}/>
                                </div>

                                <div className="form__row">
                                    <label>Quantidade de Links</label>
                                    <input type="text" id="qtd" name="qtd" value={newLink.qtd} className="form__input required" onChange={_HandleChange}/>
                                </div>
                            </div>

                            <button className="button button--full button--main pointer" onClick={RequestLink} disabled={newLink.nome===''||newLink.expires===''}> GERAR NOVO LINK </button> 
                        
                            {Array.isArray(links) && links.length > 0 && links.map((item, i) => (
                                <div className="hash pointer" onClick={() => {ToClipboard(links.join("\r\n \r\n"))}}>{item}</div>
                            ))}
                            

                            <br />
                            <div>
                                <center>
                                    {(link!=='' || (Array.isArray(links) && links.length > 0))  && (<span style={{color:'red'}}>Clique no link para copiar</span>)}
                                </center>
                            </div>
                        </div>
                       

                        
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}