import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import $ from 'jquery';
import { Context } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import ReactGA from 'react-ga';


export default function Login() {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [state, setState] = useState({
        login: "",
        password: "",
        code2fa: "",
        remember: false
    });
    const [pageLoading, setPageLoading] = useState(false);
    const [pwdState, setPwdState] = useState({ message: "", color: "#FFFFFF" });
    const [step, setStep] = useState(1);
    const [recoverStep, setRecoverStep] = useState(1);
    const [qr2fa, setQr2fa] = useState("");


    const [recover, setRecover] = useState({
        email: "",
        code: "",
        senha: "",
        resenha: "",
        token: ""
    });

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const loadedRef = useRef(false);
    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        if(!loadedRef.current) {
            loadedRef.current = true;
            handleLogout(true);
            Init();
        }
    }, []);


    useEffect(() => {
        console.log(loading);
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Core
    const Init = () => {
        const _userpwd = localStorage.getItem('userpwd');
        
        if (_userpwd) {
            let _u = JSON.parse(_userpwd);
            //console.log(_u);
            setState(_u);
        } else {
            localStorage.removeItem('userpwd');
        }
    };
    //#endregion Core


    //#region Handlers
    const _HandleChange = (e) => {
        const { id, value, type, checked } = e.target;
        //console.log(id, value);

        if(type === "checkbox") {
            setState(prevState => ({ ...prevState, [id]: checked }));
        } else {
            setState(prevState => ({ ...prevState, [id]: value }));
        }
    }


    const _handleLogin = async(event) => {
        //event.preventDefault();
        let _state = Object.assign({}, state);
        let _userpwd = localStorage.getItem('userpwd');

        setState({ login: _state.login, password: _state.password, remember: _state.remember  });


        if(_state.remember) {
            localStorage.setItem('userpwd', JSON.stringify({ login: _state.login, password: _state.password, remember: _state.remember }));
        } else {
            localStorage.removeItem('userpwd');
        }

        _userpwd = localStorage.getItem('userpwd');
        await handleLogin(_state);
    };
    //#endregion Handlers

    
    return (
        <div className="page page--login" data-page="login">
            <header className="header _header--fixed">	
                {/*<div className="header__inner">	
                    <div className="header__icon"><a href="index.html"><img src="images/icons/arrow-back.svg" alt="" title=""/></a></div>	
                </div>*/}
            </header>

            <br /> 
            <br />
            <br />
            <br />
            <br />

            <div className="login">
                <div className="login__content">	
                    <h2 className="login__title">Login</h2>
                        <div className="login-form">
                            <div id="LoginForm">
                                <div className="login-form__row">
                                    <label className="login-form__label">Nome de usuário</label>
                                    <input type="text" id="login" name="login" onChange={_HandleChange} value={state.login} className="login-form__input required"/>
                                </div>
                                <div className="login-form__row">
                                    <label className="login-form__label">Senha</label>
                                    <input type="password" id="password" name="password" onChange={_HandleChange} value={state.password} className="login-form__input required" />
                                </div>
                               <br />
                                <div className="login-form__row">
                                    <input type="button" name="button" className="login-form__submit button button--main button--full" onClick={_handleLogin} value="ENTRAR" />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}