import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga';
import './index.css';


export default function Apostas() {
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    //#endregion Effects

    //#region Handlers
    const GoBack = () => {
        window.history.go(-1);
        setTimeout(() => {window.location.reload();}, 100);
        
    }
    //#endregion Handlers


    return (
        <div>
            <div className="_panel _panel--left">
                <div className="_panel__navigation">
                    <div className="_swiper-wrapper">
                        <div className="_swiper-slide">
                            <div className="subnav-header closepanel"><img src="/images/icons/arrow-back.svg" alt="" title="" onClick={() => GoBack()}/></div>


                            <div className="form_spacing">
                                <h2 className="page__title">Entenda os tipos de apostas</h2>

                                <div className="cards cards--11 mb-20">
                                    <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
                                        <div className="card__icon"><img src="/images/icons/mobile.svg" alt="" title=""/></div>
                                        <div className="card__details">
                                            <h4 className="card__title">Aposta Mínima</h4>
                                            <p className="card__text">de R$ 0,50 a R$ 2,00 </p>
                                        </div>

                                    </div>

                                    <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
                                        <div className="card__icon"><img src="/images/icons/mobile.svg" alt="" title=""/></div>
                                        <div className="card__details">
                                            <h4 className="card__title">Aposta Padrão</h4>
                                            <p className="card__text">de R$ 2,00 a R$ 10,00 </p>
                                        </div>
                                    </div>

                                    <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
                                        <div className="card__icon"><img src="/images/icons/mobile.svg" alt="" title=""/></div>
                                        <div className="card__details">
                                            <h4 className="card__title">Aposta Máxima</h4>
                                            <p className="card__text">de R$ 10,00 a R$ 20,00 </p>
                                        </div>
                                    </div>

                                </div>

                               
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}