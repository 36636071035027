import React, { useRef, useState, useContext, useEffect, forwardRef, useImperativeHandle } from 'react';
import { HubContext } from '../../Context/HubContext';
import { Uuid } from '../../scripts/StringUtils';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useNavigate } from 'react-router-dom';
import './index.css';


let connectionHub;
let connectionHubConnected = null;
let connectionHubTimeout;
let retryable = true;
let current_uuid = "";

export const Header = () => {
    const { Hub } = useContext(HubContext);
    const [users, setUsers] = useState(0);
    const navigate = useNavigate();

    //#region Effect
    useEffect(() => {
        connectionHubConnected = null;
        Hub.Observer("SignalR");
        Hub.Set("HEADER_CONNECTHUB", (me) => {
            console.log(connectionHub?.state);
            if(connectionHub?.state !== HubConnectionState.Connected) ConnectHub(me)   
        });


        Hub.Set("HEADER_HUB_USERSCOUNT", () => {
            if(connectionHub?.state === HubConnectionState.Connected) {
                UsersCount(current_uuid);
            }
        });

        return () => {
            clearTimeout(connectionHubTimeout);
        }
    }, []);
    //#endregion Effect




    //#region Init
    const ConnectHub = async(me) => {
        console.log("ConnectHub");
        try {
            connectionHub.stop();
            connectionHub.off("Refused");
            connectionHub.off("Accepted");
            connectionHub.off("OnList");
            connectionHub.off("Ping");
            connectionHub.off("OnHeartbeat");
            connectionHub.off("Disconnect");
            connectionHub.off("Expired");
            connectionHub.off("ReceiveMessage");
            connectionHub.off("ReceiveObject");
        } catch (e) { }
        setTimeout(async() => {await Promise.resolve(TryConnect(Uuid(), me));}, 100);
    }
    //#endregion Init



     //#region Handlers
     const _HandleGoTo = (page) => {
        navigate(page);
    }
    //#endregion Handlers


    
    //#region Connection SignalR
    const TryConnect = async(uuid, me) => {
        current_uuid = uuid;

        if(!connectionHub) {
            connectionHub = new HubConnectionBuilder().withUrl("https://socket.kslot.com.br/stream").withAutomaticReconnect().build();
        }


        try {
            await connectionHub.stop();
            connectionHub.off("Refused");
            connectionHub.off("Accepted");
            connectionHub.off("OnList");
            connectionHub.off("Ping");
            connectionHub.off("OnHeartbeat");
            connectionHub.off("Disconnect");
            connectionHub.off("Expired");
            connectionHub.off("ReceiveMessage");
            connectionHub.off("ReceiveObject");
        } catch(e) { }


        return connectionHub.start().then(() => {
            connectionHub.on("Refused", (message, stack) => {
                connectionHubConnected = false;
                retryable = false;
                console.log("Refused", message, stack, connectionHubConnected);
                Hub.Exec("PLATAFORM_EXPIRED");
                connectionHub.close();
                connectionHub = null;
            });

            connectionHub.on("Accepted", (message, stack) => {
                connectionHubConnected = true;
                console.log("Accepted", message, stack, connectionHubConnected);
                if(!stack || stack === '' || stack=== []) {
                    Hub.Exec("PLATAFORM_MAINTANANCE");
                } else {
                    Hub.Exec("PLATAFORM_INIT", JSON.parse(stack));
                }
            });

            connectionHub.on("OnList", (message, stack) => {
                console.log("OnList", message, stack, connectionHubConnected);
            });

            connectionHub.on("Ping", (message, stack) => {
                console.log("Ping", message, stack, connectionHubConnected);
            });

            connectionHub.on("OnHeartbeat", (message, stack) => {
                console.log("OnHeartbeat", message, stack, connectionHubConnected);
            });

            connectionHub.on("Disconnect", (message, stack) => {
                //console.log("Disconnect", message, stack, connectionHubConnected);
            });

            connectionHub.on("Expired", (message, stack) => {
                console.log("Expired", message, stack, connectionHubConnected);
                retryable = false;
                Hub.Exec("PLATAFORM_EXPIRED");
            });

            connectionHub.on('ReceiveMessage', (message, fromUser) => {
                //console.log(`${message}:${fromUser}`);
                //console.log(JSON.parse(message));
            });
        
            connectionHub.on('ReceiveObject', (message, fromUser) => {
                //console.log(`${message}:${fromUser}`);
                //console.log(message);
                Hub.Notify("SignalR", JSON.parse(message));
            });

            connectionHub.on('UsersCount', (message, fromUser) => {
                //console.log(`${message}:${fromUser}`);
                ShowUsers(message);
            });

            connectionHub.onclose(async () => {
                if(retryable) setTimeout(() => { TryConnect(uuid, me); }, 3000);
            });


            //console.log(uuid, me);
            connectionHub.invoke("Connect", uuid, me).catch(function (err) {
                return console.error(err.toString());
            });

            
            //KeepAlive();
        }).catch((error) => console.log(error));
    }


    const KeepAlive = () => {
        clearTimeout(connectionHubTimeout);
        connectionHubTimeout = setTimeout(() => {
            connectionHub.invoke("Ping").catch(function (err) {
                return console.error(err.toString());
            });

            KeepAlive();
        }, 5000);
    }


    const UsersCount = () => {
        if(connectionHub?.state === HubConnectionState.Connected) {
            connectionHub.invoke("UsersCount", current_uuid ).catch(function (err) {
                return console.error(err.toString());
            });
        }
    }


    const ShowUsers = (c) => {
        setUsers(c);

        setTimeout(() => {
            setUsers(0);
        }, 3000);
    }
    //#endregion Connection SignalR


    return (
        <header className="header _header--fixed">	
            <div className="header__inner">	
                {users===0 ? (
                    <div className="header__logo header__logo--text"><a onClick={UsersCount}>KSLOT.<strong>COM.BR</strong></a></div>	
                ) : (
                    <div className="header__logo header__logo--text"><a><strong>{users}</strong> CONNECTIONS</a></div>	
                )}
                
                <div className="header__icon open-panel" data-panel="left"><img src="/images/icons/user.svg" alt="image" title="image" onClick={() => _HandleGoTo("/login")}/></div>
            </div>
        </header>
    )
}