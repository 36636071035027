import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext';
import { InvalidatorContext } from '../../Context/InvalidatorContext';
import { Req, Request } from '../../scripts/Request';
import { WaitObject } from '../../scripts/Wait';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';

let games_arr = [];
let update_timer;
let blink_timer;
let blink_index = 0;
let UPDATE_TIMER_TIMEOUT = 90000;
let search_txt = "";

export default function Plataforma() {
    const component_name = "plataforma";
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const { Hub } = useContext(HubContext);
    const [plataformas, setPlataformas] = useState([]);
    const [games, setGames] = useState([]);
    const [search, setSearch] = useState("");
    const [isMaintenance, setIsMaintenance] = useState(false);
    const [presentation, setPresentation] = useState("As melhores plataformas você encontra aqui");
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();

    const { Name, Hash } = useParams();

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        


        let wait1 = setTimeout(() => {
            if(plataformas.length === 0) {
                LoadPlataformas();
                //LoadGames();
                LoadPresentation();
            }
        }, 500);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Unsubscribe("SignalR", component_name);
                    Hub.Clear(component_name);
                    clearInterval(blink_timer);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        Hub.Set("PLATAFORM_INIT", (arr) => {
            LoadPlataformas();
            LoadGames(undefined, arr);
            LoadPresentation();
        });

        Hub.Set("PLATAFORM_EXPIRED", () => {
            console.log("PLATAFORM_EXPIRED");
            setPlataformas([]);
            setGames([]);
        });
        Hub.Exec("HEADER_CONNECTHUB", Hash);


        update_timer = setTimeout(() => { LoadGames(true, games_arr) }, UPDATE_TIMER_TIMEOUT);

        Hub.Subscribe("SignalR", (data) => { 
            //console.log(data);
            Update(data)
        }, component_name);


        blink_timer = setInterval(() => {
            let _tmp = games_arr.filter(item => { return item.name.toLowerCase().indexOf(search_txt.toLowerCase()) > -1})
            if(blink_index >= _tmp.length) {
                blink_index = 0;
            }

            try {
            let blinkingDiv = document.getElementById('game'+blink_index);
                blinkingDiv.style.animation = 'blink 1s linear 1';
                blinkingDiv.addEventListener('animationend', function() {
                    blinkingDiv.style.animation = '';
                    blinkingDiv.style.backgroundColor = ''; // Retorna à cor de fundo original
                }, { once: true });

                blink_index++;
            } catch (e) {}
        }, 100);
    }


    const Update = (data) => {
        console.log("Update", data);
        clearTimeout(update_timer);

        if(Array.isArray(data)) {
            if(data[0].name === "Fortune Rabbit") {
                window.location.href = "/Plataforma/"+ Hash;
            }

            let _tmp = games_arr.filter(item => { return item.name.toLowerCase().indexOf(search_txt.toLowerCase()) > -1});

            data.map(item => {
                let _index = _tmp.findIndex(g => g.name === item.name);
                if(_index > -1) {
                    _tmp[_index].padrao = item.padrao;
                    _tmp[_index].minima = item.minima;
                    _tmp[_index].maxima = item.maxima; 
                }
            });



            setGames(_tmp);
            setIsMaintenance(false);
            forceUpdate();
        } else {
            if(games_arr.name === "Fortune Rabbit") {
                window.location.href = "/Plataforma/"+ Hash;
            }

            let _tmp = games_arr.filter(item => { return item.name.toLowerCase().indexOf(search_txt.toLowerCase()) > -1});
            let _index = _tmp.findIndex(g => g.name === data.id);
            if(_index > -1) {
                _tmp[_index].padrao = data.padrao;
                _tmp[_index].minima = data.minima;
                _tmp[_index].maxima = data.maxima;
                setGames(_tmp);
                setIsMaintenance(false);

                /*let blinkingDiv = document.getElementById('game'+_index);
                blinkingDiv.style.animation = 'blink 1s linear 1';
                blinkingDiv.addEventListener('animationend', function() {
                    blinkingDiv.style.animation = '';
                    blinkingDiv.style.backgroundColor = ''; // Retorna à cor de fundo original
                }, { once: true });*/
                forceUpdate();
            }
        }

        update_timer = setTimeout(() => { LoadGames(true, games_arr) }, UPDATE_TIMER_TIMEOUT);
    }
    //#endregion Init



    //#region Loaders
    const LoadPlataformas = async() => {
        let result = await Req("api/Plataformas/List/1", "GET", "", "", 0)
        .then((data) => {
            setPlataformas(data);
            InitSwiper();
        })
        .catch(() => {
            window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (PLM 001)", "error");
        });
    }


    const LoadGames = (maintenance, arr) => {
        //console.log("LoadGames", arr);
        let plataformas = Req("/json/images.json", "GET", "", "")
        .then(data => {
            games_arr = [];

            if(arr && arr.length > 2) {
                //console.log(arr);

                data.map(item => {
                    let index = arr.findIndex(t => t.name == item.name);
                    if(index > -1) {
                        //console.log(arr[index]);
                        games_arr.push({img: item.img, name: item.name, id: item.name, padrao: arr[index].padrao, minima: arr[index].minima, maxima: arr[index].maxima});
                    } else {
                        games_arr.push({img: item.img, name: item.name, id: item.name, padrao: 0, minima: 0, maxima: 0});
                    }
                });

                //console.log(games_arr);

                setGames(games_arr);
                forceUpdate();

                if(maintenance) {
                    setIsMaintenance(true);
                }
            } else {
                games_arr = data;
            }

            
        })
        .catch(err => {});
    }


    const LoadPresentation = async() => {
        let result = await Req("api/Config/LoadPresentation", "GET", "", "", 0)
        .then((data) => {
            setPresentation(data);
        })
        .catch(() => { window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (CFN 003)", "success"); })
    }
    //#endregion Loaders



    //#region Swiper Init
    const InitSwiper = () => {
        setTimeout( () => { 
            var simplesliders = [];
            var simpleslider = [];
            window.$('.slider-init').each(function(index, element){
                
                var paginationtype = window.$(this).attr("data-paginationtype");
                var spacebetweenitems = window.$(this).data("spacebetweenitems");
                var itemsperview = window.$(this).data("itemsperview");
                window.$(this).addClass('s'+index);
                window.$(this).prev().children(".page__title-right").children(".swiper-button-next").addClass('swiper-button-next'+index);
                window.$(this).prev().children(".page__title-right").children(".swiper-button-prev").addClass('swiper-button-prev'+index);
                window.$(this).children(".swiper-pagination").addClass('swiper-pagination'+index);
                simpleslider[index] = new window.Swiper('.s'+index, {
                    direction: 'horizontal',
                    effect: 'slide',
                    slidesPerView: itemsperview,
                    spaceBetween: spacebetweenitems,
                    pagination: {
                    el: '.swiper-pagination'+index,
                    type: paginationtype,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next'+index,
                        prevEl: '.swiper-button-prev'+index
                    }
                    
                    });
                simplesliders.push(simpleslider[index]);
            });


            var cartsliders = [];
            var cartslider = [];
            window.$('.slider-init-swipe').each(function(index, element){
                
                window.$(this).addClass('c'+index);
                cartslider[index] = new window.Swiper('.c'+index, {
                    direction: 'horizontal',
                    effect: 'slide',
                    slidesPerView: 'auto',
                    spaceBetween: 0
                    
                    });
                cartsliders.push(cartslider[index]);
            });
            
            window.$(".cart__item-more").on('click', function(e) {
                var swipeitem = window.$(this).data("swipe-item");
                cartslider[swipeitem].slideNext();
            });
        }, 100);
        
    }
    //#endregion Swiper Init


     //#region Handlers
     const _HandleGoTo = (page) => {
        navigate(page);
     }


     const _HandleChange = (event) => {
        setSearch(event.target.value);
        search_txt = event.target.value;
        let tmp = games_arr.filter(item => { return item.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1});
        setGames(tmp);
     }


     const _HandleDoubleClick = (event) => {
        setSearch("");
        search_txt = "";
        setGames(games_arr);
     }
     //#endregion Handlers

    return (
        <>
            {Array.isArray(plataformas) && plataformas.length > 0 && !isMaintenance ? (<>
            <div className="body-overlay"></div>
            

            <div className="page page--main" data-page="main">
                

                <div className="page__content page__content--full page__content--with-bottom-nav">
                    <div className="account-info">
                        <div className="img-thumb">
                            <div className="round-img">
                                <img className="" src={"/db/logo_camila.jpg?" + (Invalidator['profile_image'])} />
                            </div>
                        </div>
			            <div className="account-text"><div>{presentation}</div></div>
                        <svg className="account-info__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <path d="M0,0 Q50,201 100,0 L100,100 0,100 Z" fill="#0f0638"/>
                        </svg>
                    </div>

                    <br />
                    <br />
                    <div className="page-inner">
                        <div className="page__title-bar">
                            <h3>&nbsp; PLATAFORMAS PAGANTES DO DIA</h3>
                            
                            <div className="page__title-right">
                                <div className="swiper-button-prev slider-portfolio__prev"></div>
                                <div className="swiper-button-next slider-portfolio__next"></div>
                            </div>
                        </div>

                        <div className="swiper-container slider-portfolio slider-portfolio--round-corners slider-init mb-40" data-paginationtype="progressbar" data-spacebetweenitems="10" data-itemsperview="auto">
                            <div className="swiper-wrapper">

                                { Array.isArray(plataformas) && plataformas.length > 0 && plataformas.map((item, i) => (
                                    <div key={'plat'+i} className="swiper-slide slider-portfolio__slide slider-portfolio__slide--1h">
                                        <div className="slider-portfolio__caption caption">
                                            <div className="caption__content">
                                                <div className="platform">
                                                    <img className="plat_img" src={"/db/"+item.img} />
                                                </div>
                                                
                                                <div className="platform">
                                                    <div className="platform_text">
                                                        <div className="platform_title">{item.title}</div>
                                                        <div className="platform_subtitle">{item.subtitle}</div>
                                                        <div className="btn-invite"><a className="button button--full button--main" href={item.url} target="_blank">Clique Aqui</a></div>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                ))}
                            
                            </div>
                            <div className="swiper-pagination slider-portfolio__pagination"></div>
                        </div>



                        <div className="page__title-bar">
                            <div className="search__form">
                                <form>
                                    <input type="text" className="search__input" id="search" name="search" value={search} onChange={_HandleChange} onDoubleClick={_HandleDoubleClick}  placeholder="Buscar" />
                                    <input type="submit" className="search__submit" value="Send" />
                                </form>		
                            </div>

                            <div className="page__title-right">
                                {/*<a className="button button--full button--main pointer" onClick={() => _HandleGoTo("/Porcentagens/" + Hash)}>OUTROS JOGOS</a>*/}
                                <a className="button button--full _button--main pointer" onClick={() => _HandleGoTo("/Apostas")}> &nbsp; ENTENDA AS APOSTAS &nbsp;  </a>
                            </div>
                        </div>	


                        <div className="cards cards--11">
                            {Array.isArray(games) && games.length > 0 && games.map((item, i) => (
                                <div key={'game'+i} id={'game'+i} className="card-coin">
                                   <div className="game">
                                        <div className="game_image">
                                            <img className="game_icon" src={"/images/logos2/"+item.img} />
                                        </div>
                                   </div>

                                   <div className="game">
                                        <div className="game_title">{item.name}</div>
                                   </div>

                                   <div className="game">
                                        <div className="game_content">
                                            <div className="game_bar">
                                                <div className={"game_bar_fill " + (item.padrao>69?'game_green':(item.padrao>40?'game_yellow':'game_red'))} style={{width:item.padrao+'%'}}>&nbsp;<span>{(item.padrao||0)}%</span>&nbsp;</div>
                                                <div className="game_bet">Padrão</div>
                                            </div>
                                        </div>
                                   </div>

                                   <div className="game">
                                        <div className="game_content">
                                            <div className="game_bar">
                                                <div className={"game_bar_fill " + (item.minima>69?'game_green':(item.minima>40?'game_yellow':'game_red'))} style={{width:item.minima+'%'}}>&nbsp;<span>{item.minima||0}%</span>&nbsp;</div>
                                                <div className="game_bet">Mínima</div>
                                            </div>
                                        </div>
                                   </div>

                                   <div className="game">
                                        <div className="game_content">
                                            <div className="game_bar">
                                                <div className={"game_bar_fill " + (item.maxima>69?'game_green':(item.maxima>40?'game_yellow':'game_red'))} style={{width:item.maxima+'%'}}>&nbsp;<span>{item.maxima||0}%</span>&nbsp;</div>
                                                <div className="game_bet">Máxima</div>
                                            </div>
                                        </div>
                                   </div>
                               </div>
                            ))}
                        </div>

                        <br />
                        <br />

                        <center>
                            <div style={{width: '85%'}}>
                                <h2>ATENÇÃO !!!</h2>
                                <br />
                                <h3>⛔️⚠️ Trabalhamos com as melhores probabilidades, mas não garantimos ganho!!! 
Por isso não use dinheiro comprometido em jogo, risco de perda. Jogue com responsabilidade!!! Proibido para menores de 18 anos. 🔞🔞🔞🔞🔞🔞⚠️</h3>
                                <br />
                                <br />
                                <br />
                                
                            </div>
                        </center>

                        <br />            
                        <br /> 
                        <br /> 
                        <br /> 
                        <br /> 
                        <br />             
                        
                    </div>
                </div>    
            </div>

            </>) : (<>
                {!isMaintenance ? (
                     <div className="unauthorized">
                     <img src="/images/access_denied.webp" />
                    </div>
                ) : (
                    <div className="maintenace">
                       <center>
                            <div style={{width: '85%'}}>
                                <h2>POR FAVOR AGUARDE...</h2>
                                <img src="/images/maintenace.png" style={{width:'270px', height:'219px'}}/>
                                <br />
                                <h3>Os servidores de probabilidade necessitam de manutenção e rebalanceamento constante devido a entrada de novas plataformas.</h3>
                                <br />
                                <br />
                                <br />
                                <span><a onClick={forceUpdate}>Tente atualizar a página novamente em alguns minutos</a></span>
                            </div>
                        </center>
                       
                    </div>
                ) }
               
            </>)}
        </>
    )
}