import { useState, useEffect, useRef } from 'react';
import { Request } from '../../scripts/Request';
import { GetServer } from '../../scripts/Loadbalancer';
import { useNavigate } from 'react-router-dom';



export default function useAuth() {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({ user_id: "", entidade_id: "", nome: "", token: "", acesso: "", nivel: "", code: "", validade: "" });
    const authRef = useRef(false);
    const tokenRef = useRef("");
    const navigate = useNavigate();



    useEffect(() => {
        //GetServer();
        const _user = localStorage.getItem('user');

        if (_user) {
            let _u = JSON.parse(_user);
            setUser(_u);
            setAuthenticated(true);
            authRef.current = true;
            tokenRef.current = _u.token;
        }
        setLoading(false);
    }, []);


    async function UserValidate() {
        const _user = localStorage.getItem('user');

        if (_user) {
            let _u = JSON.parse(_user);
            setUser(_u);
            setAuthenticated(true);
            authRef.current = true;;
            tokenRef.current = _u.token;
        }
        setLoading(false);

        return JSON.parse(_user);
    }


    async function Refresh () {
        const user = await Promise.resolve(new Request().Run("api/Auth/Refresh", "Post", {token: tokenRef.current}, tokenRef.current, 0))
            .then((data) => {
                return data;
            });
        

        if(user.user_id) {
            localStorage.setItem('user', JSON.stringify(user));
            setAuthenticated(true);
            authRef.current = true;
            setUser(user);
            tokenRef.current = user.token;
        }
        return true;
    }


    async function handleLogin(form) {
        //setLoading(true);
        const user = await Promise.resolve(new Request().Run("api/Auth/Login", "Post", form, "", 0))
            .then((data) => {
                return data;
            });

         //setLoading(false);


        if(user?.ativo === 9) {
            return { tfa: user.token }
        } else if (user?.user_id === 0 || user?.token === "") {
            window.swal("Acesso não autorizado", "Senha Incorreta", "warning");
        } else if(user?.user_id) {
            localStorage.setItem('user', JSON.stringify(user));
            setAuthenticated(true);
            authRef.current = true;
            setUser(user);
            tokenRef.current = user.token;
            if (user.nivel === 7) {
                try {
                    //localStorage.removeItem('userpwd');
                } catch(e) { }
            } else {
                navigate('/Admin/Home');
            }
        
        }
    }


    async function handleLogout(reload) {
        setAuthenticated(false);
        authRef.current = false;
        setUser({ user_id: "", nome: "", token: "", acesso: "", nivel: "", code: "", validade: "" });
        localStorage.removeItem('user');
        if(!reload) navigate('/');
    }

 
    async function Update(data) {
        if(authRef.current) {
            if(data.user_id) {
                localStorage.setItem('user', JSON.stringify(data));
                setAuthenticated(true);
                authRef.current = true;
                setUser(data);
                tokenRef.current = data.token;
            }
        } else {
            navigate('/');
        }
    }

    return { authenticated, loading, handleLogin, handleLogout, user, UserValidate, Refresh, Update };
}