import React, { useRef } from 'react';

import './index.css';





export default function Footer() {
    

    return (
        <div id="Footer" className="bottom-toolbar">
            <div className="bottom-navigation bottom-navigation--gradient">
                <ul className="bottom-navigation__icons">
                    <li></li>
                    <li className="_centered"><a href="https://www.facebook.com/kslot777plataformas" target="_blank"><img src="/images/icons/icons8-facebook-novo.svg" alt="" title="" /></a></li>
                    <li className="centered"><a href="https://chat.whatsapp.com/KHDacDJEkxQA6yHJDi8sqv" target="_blank"><img src="/images/icons/icons8-whatsapp.svg" alt="" title="" /></a></li>
                    <li className="_centered"><a href="https://www.instagram.com/camilamartins1519/" target="_blank"><img src="/images/icons/icons8-instagram.svg" alt="" title="" /></a></li>
                    <li></li>
                </ul>
            </div>	  
        </div>
    )
}