import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { useNavigate } from 'react-router-dom';
import { UploadService } from '../../../scripts/UploadService';
import ReactGA from 'react-ga';
import './index.css';
import { Req } from '../../../scripts/Request';


export default function Config() {
    const component_name = "admin/config";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [image, setImage] = useState("");
    const hiddenFileInput = useRef(null);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const [presentation, setPresentation] = useState("");
    const navigate = useNavigate();

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        setTimeout(async() => { await Invalidate('profile_image');}, 10);
        LoadPresentation();
    }
    //#endregion Init


     //#region Loaders
    const LoadPresentation = async() => {
        let result = await Req("api/Config/LoadPresentation", "GET", "", user.token, 0)
        .then((data) => {
            setPresentation(data);
        })
        .catch(() => { window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (CFN 003)", "error"); })
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleGoTo = (page) => {
        navigate(page);
    }


    const _handleUpload = () => {
        hiddenFileInput.current.click();
    }

    const onImageChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            UploadImages(e);
        };
        reader.readAsDataURL(files[0]);
    }


    const UploadImages = async(event) => {
        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        upload.OnEnd = async(result) => {
            let arr = JSON.parse(result.img);
            c++;

            if(c === event.target.files.length) {
                setPageLoading(false);
                await Invalidate('profile_image');
                //forceUpdate();
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

        await upload.PopUpload("https://kslot.com.br/api/Config/Upload", user.token);
    }



    const PresentationChange = (event) => {
        setPresentation(event.target.value);
    }


    const PresentationSave = async() => {
        let result = await Req("api/Config/SavePresentation", "POST", { presentation: presentation}, user.token, 0)
        .then((data) => {
            if(data.toString() === "loaded") {
                window.swal("Sucesso", "Texto atualizado com sucesso", "success");
            } else {
                window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (CFN 001)", "error");
            }
        })
        .catch(() => {
            window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (CFN 002)", "error");
        });
    }
    //#endregion Handlers



    return (
        <div>
            <div className="_panel _panel--left">
                <div className="_panel__navigation">
                    <div className="_swiper-wrapper">
                        <div className="_swiper-slide">
                            <div className="subnav-header closepanel"><img src="/images/icons/arrow-back.svg" alt="" title="" onClick={() => _HandleGoTo("/Admin/Home")}/></div>

                            <div className="user-details">
                                <div className="img-thumb">
                                    <div className="round-img">
                                        <img className="" src={"/db/logo_camila.jpg?" + (Invalidator['profile_image'])} />
                                    </div>
                                </div>
                                <div className="user_camera pointer" onClick={_handleUpload}>
                                    <img src="/images/camera.png" alt="" />
                                </div>
                                <div className="user-details__title"><span>Upload novo logotipo</span> para kslot.com.br</div>
                                <input type="file" ref={hiddenFileInput} onChange={onImageChange} style={{display: 'none'}} accept=".jpg,.png"/>
                            </div>

                            <br />

                            <div className="form_spacing">
                                <h2 className="page__title">Texto de Apresentação</h2>

                                <div className="fieldset">
                                    <div className="form__row">
                                        <textarea id="presentation_text" name="presentation_text" value={presentation} className="form__input required" onChange={PresentationChange}>
                                            {presentation}
                                        </textarea>
                                    </div>
                                </div>

                                <div class="_bottom-fixed-button">
                                    <a className="button button--full button--main pointer" onClick={PresentationSave}>SALVAR TEXTO</a>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}