import React, { useEffect, useContext, useState, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';




export default function Home() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
   
    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                    Hub.Unsubscribe("SignalR");
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        setTimeout(async() => { await Invalidate('profile_image');}, 10);
    }
    //#endregion Init

    //#region Handlers
    const _HandleGoTo = (page) => {
        navigate(page);
    }
    //#endregion Handlers


    return (
        <div>
            <div className="_panel panel--left">
                <div className="panel__navigation">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="subnav-header closepanel"><img src="images/icons/arrow-back.svg" alt="" title="" onClick={() => _HandleGoTo("/login")}/></div>

                            <div className="user-details">
                                <div className="img-thumb">
                                    <div className="round-img">
                                        <img className="" src={"/db/logo_camila.jpg?" + (Invalidator['profile_image'])} />
                                    </div>
                                </div>
                                <div className="user-details__title"><span>Seja bem vindo!</span> {user?.nome}</div>
                            </div>

                            <br />
                            <br />
                            
                          

                            <nav className="main-nav">
                                <ul>
                                    <li><a onClick={() => _HandleGoTo("/Admin/Config")}><img src="/images/icons/user.svg" alt="" title="" /><span>Configurar Informações</span></a></li>
                                    <li><a onClick={() => _HandleGoTo("/Admin/Plataformas")}><img src="/images/icons/settings.svg" alt="" title="" /><span>Cadastro de Plataformas</span></a></li>
                                    <li><a onClick={() => _HandleGoTo("/Admin/Links")}><img src="/images/icons/listing.svg" alt="" title="" /><span>Gerar Links</span></a></li>
                                </ul>
                            </nav>
                            
                        </div>
                    </div>

                    <br />
                    <br />
                    <br />
                  
                </div>
            </div>
        </div>
    )
}