import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext';
import { InvalidatorContext } from '../../Context/InvalidatorContext';
import { Req, Request } from '../../scripts/Request';
import { WaitObject } from '../../scripts/Wait';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';

let games = [];
let blinkings = [];
let update_timer;
let UPDATE_TIMER_TIMEOUT = 60000;
let timer_prob;

export default function Porcentagens() {
    const component_name = "porcentagens";
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const { Hub } = useContext(HubContext);
    const [plataformas, setPlataformas] = useState([]);
    //const [games, setGames] = useState([]);
    const [isMaintenance, setIsMaintenance] = useState(false);
    const [presentation, setPresentation] = useState("As melhores plataformas você encontra aqui");
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();

    const { Name, Hash } = useParams();

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            clearInterval(timer_prob);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        LoadPlataformas();
        LoadGames();
        LoadPresentation();

        Hub.Set("PLATAFORM_EXPIRED", () => {
            setPlataformas([]);
            //setGames([]);
            games = [];
        });


        Hub.Exec("HEADER_CONNECTHUB", Hash);
    }

    const Timer = () => {
        let counter = 1;

        timer_prob = setInterval(() => {
            ProtoProb(counter);
            counter++;

            if(counter >= 106) counter = 1;
        }, 60)
    }

    //#endregion Init



    //#region Loaders
    const LoadPlataformas = async() => {
        let result = await Req("api/Plataformas/List/1", "GET", "", "", 0)
        .then((data) => {
            setPlataformas(data);
            InitSwiper();
        })
        .catch(() => {
            window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (PLM 001)", "error");
        });
    }


    const LoadGames = (maintenance) => {
        /*let plataformas = Req("/json/games.json", "GET", "", "")
        .then(data => {
            games_arr = data;
            setGames(data);

            if(maintenance) {
                setIsMaintenance(true);
            }
        })
        .catch(err => {});*/

        let games_arr = [];
        for(let i=1; i<=105; i++) {
            games_arr.push({id: i, percent: 0});
        }

        //console.log(games_arr.length);
        //setGames(games_arr);

        games = games_arr;

        if(maintenance) {
            setIsMaintenance(true);
        }

        setTimeout(() => { 
            Timer(); 
        }, 100);
    }


    const LoadPresentation = async() => {
        let result = await Req("api/Config/LoadPresentation", "GET", "", "", 0)
        .then((data) => {
            setPresentation(data);
        })
        .catch(() => { window.swal("Alerta", "Ocorreu um erro, reza pra Deus ajudar (CFN 003)", "success"); })
    }
    //#endregion Loaders



    //#region Swiper Init
    const InitSwiper = () => {
        setTimeout( () => { 
            var simplesliders = [];
            var simpleslider = [];
            window.$('.slider-init').each(function(index, element){
                
                var paginationtype = window.$(this).attr("data-paginationtype");
                var spacebetweenitems = window.$(this).data("spacebetweenitems");
                var itemsperview = window.$(this).data("itemsperview");
                window.$(this).addClass('s'+index);
                window.$(this).prev().children(".page__title-right").children(".swiper-button-next").addClass('swiper-button-next'+index);
                window.$(this).prev().children(".page__title-right").children(".swiper-button-prev").addClass('swiper-button-prev'+index);
                window.$(this).children(".swiper-pagination").addClass('swiper-pagination'+index);
                simpleslider[index] = new window.Swiper('.s'+index, {
                    direction: 'horizontal',
                    effect: 'slide',
                    slidesPerView: itemsperview,
                    spaceBetween: spacebetweenitems,
                    pagination: {
                    el: '.swiper-pagination'+index,
                    type: paginationtype,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next'+index,
                        prevEl: '.swiper-button-prev'+index
                    }
                    
                    });
                simplesliders.push(simpleslider[index]);
            });


            var cartsliders = [];
            var cartslider = [];
            window.$('.slider-init-swipe').each(function(index, element){
                
                window.$(this).addClass('c'+index);
                cartslider[index] = new window.Swiper('.c'+index, {
                    direction: 'horizontal',
                    effect: 'slide',
                    slidesPerView: 'auto',
                    spaceBetween: 0
                    
                    });
                cartsliders.push(cartslider[index]);
            });
            
            window.$(".cart__item-more").on('click', function(e) {
                var swipeitem = window.$(this).data("swipe-item");
                cartslider[swipeitem].slideNext();
            });
        }, 100);
        
    }
    //#endregion Swiper Init


     //#region Handlers
     const _HandleGoTo = (page) => {
        navigate(page);
     }
     //#endregion Handlers


    //#region Functions
    const Update = (data) => {
        //console.log("Update", data);
        //clearTimeout(update_timer);

        if(data && games.length > 0) {
            
            let _index = data.id; //games_arr.findIndex(g => g.id === data.id);
            if(_index > -1) {
                games[_index-1].percent = data?.percent;
                //console.log(games[_index]);
                //setGames(games);
                setIsMaintenance(false);

                try {
                    blinkings[_index-1] = document.getElementById('game'+(_index));
                    blinkings[_index-1].style.animation = 'blink2 1s linear 1';
                    blinkings[_index-1].addEventListener('animationend', function() {
                        blinkings[_index-1].style.animation = '';
                        blinkings[_index-1].style.backgroundColor = ''; // Retorna à cor de fundo original
                    }, { once: false });
                } catch(err) {}

                forceUpdate();
                

            }
        }

        //update_timer = setTimeout(() => { LoadGames(true) }, UPDATE_TIMER_TIMEOUT);
    }

    const ProtoProb = (i) => {
        var x = [0, 720, 841, 920, 968, 801, 806, 951, 798, 912, 768, 857, 777, 748, 847, 914, 823, 807, 933, 932, 803, 768, 972, 928, 773, 935, 810, 978, 936, 813, 716, 940, 888, 839, 880, 867, 907, 979, 729, 957, 749, 821, 948, 874, 889, 892, 961, 779, 930, 744, 746, 869, 757, 986, 981, 999, 964, 739, 966, 765, 929, 769, 843, 856, 782, 884, 872, 956, 954, 854, 711, 887, 726, 878, 962, 886, 885, 830, 817, 983, 965, 967, 982, 963, 881, 991, 834, 941, 864, 882, 953, 826, 814, 862, 972, 763, 804, 937, 960, 702, 801, 703, 911, 863, 747, 835, 934, 891];
        const d = new Date;
        var date = d["getUTCDate"](), day = d["getUTCDay"]() + 1, year = d.getUTCFullYear(), month = d.getUTCMonth() + 1, hour = d["getUTCHours"](), min = d["getMinutes"]();
        min < 30 ? min = 1 : min = 2;
        var xx = day + year * month * date;
        xx = Math["pow"](xx, hour * min);
        xx = xx * x[i];
        if(i == 16 || i == 22 || i == 2 || i == 1 || i == 8 || i == 11 || i == 19 || i == 14 || i == 18 || i == 32 || i == 62 || i == 30 || i == 10 || i == 3 || i == 13 || i == 22 || i == 37 ) {
            xx = xx % 27;
            xx += 65   
        } else {
            xx = xx % 83;
            xx += 8;
        }

        if(!isNaN(xx)) {
            //console.log(i, xx);
            Update({id: i, percent: xx});

        }
    }


    const ProtoProbD = (i, d) => {
        var x = [0, 720, 841, 920, 968, 801, 806, 951, 798, 912, 768, 857, 777, 748, 847, 914, 823, 807, 933, 932, 803, 768, 972, 928, 773, 935, 810, 978, 936, 813, 716, 940, 888, 839, 880, 867, 907, 979, 729, 957, 749, 821, 948, 874, 889, 892, 961, 779, 930, 744, 746, 869, 757, 986, 981, 999, 964, 739, 966, 765, 929, 769, 843, 856, 782, 884, 872, 956, 954, 854, 711, 887, 726, 878, 962, 886, 885, 830, 817, 983, 965, 967, 982, 963, 881, 991, 834, 941, 864, 882, 953, 826, 814, 862, 972, 763, 804, 937, 960, 702, 801, 703, 911, 863, 747, 835, 934, 891];
        var date = d["getUTCDate"](), day = d["getUTCDay"]() + 1, year = d.getUTCFullYear(), month = d.getUTCMonth() + 1, hour = d["getUTCHours"](), min = d["getMinutes"]();
        min < 30 ? min = 1 : min = 2;
        var xx = day + year * month * date;
        xx = Math["pow"](xx, hour * min);
        xx = xx * x[i];
        if(i == 16 || i == 22 || i == 2 || i == 1 || i == 8 || i == 11 || i == 19 || i == 14 || i == 18 || i == 32 || i == 62 || i == 30 || i == 10 || i == 3 || i == 13 || i == 22 || i == 37 ) {
            xx = xx % 27;
            xx += 65   
        } else {
            xx = xx % 83;
            xx += 8;
        }

        if(!isNaN(xx)) {
            //console.log(i, xx);
            Update({id: i, percent: xx});

        }
    }
    //#endregion Functions

    return (
        <>
            {Array.isArray(plataformas) && plataformas.length > 0 && !isMaintenance ? (<>
            <div className="body-overlay"></div>
            

            <div className="page page--main Percent" data-page="main">
                

                <div className="page__content page__content--full page__content--with-bottom-nav">
                    <div className="account-info">
                        <div className="img-thumb">
                            <div className="round-img">
                                <img className="" src={"/db/logo_camila.jpg?" + (Invalidator['profile_image'])} />
                            </div>
                        </div>
			            <div className="account-text"><div>{presentation}</div></div>
                        <svg className="account-info__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <path d="M0,0 Q50,201 100,0 L100,100 0,100 Z" fill="#0f0638"/>
                        </svg>
                    </div>

                    <br />
                    <br />
                    <div className="page-inner">
                        <div className="page__title-bar">
                            <h3>&nbsp; PLATAFORMAS PAGANTES DO DIA</h3>
                            
                            <div className="page__title-right">
                                <div className="swiper-button-prev slider-portfolio__prev"></div>
                                <div className="swiper-button-next slider-portfolio__next"></div>
                            </div>
                        </div>

                        <div className="swiper-container slider-portfolio slider-portfolio--round-corners slider-init mb-40" data-paginationtype="progressbar" data-spacebetweenitems="10" data-itemsperview="auto">
                            <div className="swiper-wrapper">

                                { Array.isArray(plataformas) && plataformas.length > 0 && plataformas.map((item, i) => (
                                    <div key={'plat'+i} className="swiper-slide slider-portfolio__slide slider-portfolio__slide--1h">
                                        <div className="slider-portfolio__caption caption">
                                            <div className="caption__content">
                                                <div className="platform">
                                                    <img className="plat_img" src={"/db/"+item.img} />
                                                </div>
                                                
                                                <div className="platform">
                                                    <div className="platform_text">
                                                        <div className="platform_title">{item.title}</div>
                                                        <div className="platform_subtitle">{item.subtitle}</div>
                                                        <div className="btn-invite"><a className="button button--full button--main" href={item.url} target="_blank">Clique Aqui</a></div>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                ))}
                            
                            </div>
                            <div className="swiper-pagination slider-portfolio__pagination"></div>
                        </div>



                        <div className="page__title-bar">
                            <h3>&nbsp; PORCENTAGENS</h3>
                            <div className="page__title-right">
                                <a className="button button--ex button--main pointer" onClick={() => _HandleGoTo("/Plataforma/" + Hash)}>NOSSOS JOGOS</a>
                            </div>
                        </div>	



                        <div className="cards cards--11">
                            {
                                Array.isArray(games) && games.length > 0 && games.map((item, i) => (
                                    <div key={'game'+item.id} id={'game'+item.id} className="card-game">
                                        <div className="game">
                                            <img className="game_icon" src={"/images/logos1/"+item.id+".webp"} />
                                        </div>

                                        <div className="game">
                                        <div className="game_content">
                                            <div className="game_bar">
                                                <div className={"game_bar_fill " + (item.percent>69?'game_green':(item.percent>40?'game_yellow':'game_red'))} style={{width:item.percent+'%'}}>&nbsp;<span>{item.percent}%</span>&nbsp;</div>
                                            </div>
                                        </div>
                                   </div>
                                    </div>
                                ))
                            }          

                            {/*Array.isArray(games) && games.length > 0 && games.map((item, i) => (
                                <div key={'game'+i} id={'game'+i} className="card-coin">
                                   <div className="game">
                                        <img className="game_icon" src={"/images/logos/"+item.id+".webp"} />
                                   </div>

                                   <div className="game">
                                        <div className="game_title">{item.name}</div>
                                   </div>

                                   <div className="game">
                                        <div className="game_content">
                                            <div className="game_bar">
                                                <div className={"game_bar_fill " + (item.percen>69?'game_green':(item.percent>40?'game_yellow':'game_red'))} style={{width:item.percent+'%'}}>&nbsp;<span>{item.percent}%</span>&nbsp;</div>
                                                <div className="game_bet">Padrão</div>
                                            </div>
                                        </div>
                                   </div>

                                   <div className="game">
                                        <div className="game_content">
                                            <div className="game_bar">
                                                <div className={"game_bar_fill " + (item.minima>69?'game_green':(item.percent>40?'game_yellow':'game_red'))} style={{width:item.minima+'%'}}>&nbsp;<span>{item.minima}%</span>&nbsp;</div>
                                                <div className="game_bet">Mínima</div>
                                            </div>
                                        </div>
                                   </div>

                                   <div className="game">
                                        <div className="game_content">
                                            <div className="game_bar">
                                                <div className={"game_bar_fill " + (item.maxima>69?'game_green':(item.maxima>40?'game_yellow':'game_red'))} style={{width:item.maxima+'%'}}>&nbsp;<span>{item.maxima}%</span>&nbsp;</div>
                                                <div className="game_bet">Máxima</div>
                                            </div>
                                        </div>
                                   </div>
                               </div>
                            ))*/}
                        </div>

                        <br />
                        <br />

                        <center>
                            <div style={{width: '85%'}}>
                                <h2>ATENÇÃO !!!</h2>
                                <br />
                                <h3>⛔️⚠️ Trabalhamos com as melhores probabilidades, mas não garantimos ganho!!! 
Por isso não use dinheiro comprometido em jogo, risco de perda. Jogue com responsabilidade!!! Proibido para menores de 18 anos. 🔞🔞🔞🔞🔞🔞⚠️</h3>
                                <br />
                                <br />
                                <br />
                                
                            </div>
                        </center>

                        <br />            
                        <br /> 
                        <br /> 
                        <br /> 
                        <br /> 
                        <br />             
                        
                    </div>
                </div>    
            </div>

            </>) : (<>
                {!isMaintenance ? (
                     <div className="unauthorized">
                     <img src="/images/access_denied.webp" />
                    </div>
                ) : (
                    <div className="maintenace">
                       <center>
                            <div style={{width: '85%'}}>
                                <h2>POR FAVOR AGUARDE...</h2>
                                <img src="/images/maintenace.png" style={{width:'270px', height:'219px'}}/>
                                <br />
                                <h3>Os servidores de probabilidade necessitam de manutenção e rebalanceamento constante devido a entrada de novas plataformas.</h3>
                                <br />
                                <br />
                                <br />
                                <span><a onClick={forceUpdate}>Tente atualizar a página novamente em alguns minutos</a></span>
                            </div>
                        </center>
                       
                    </div>
                ) }
               
            </>)}
        </>
    )
}