import React, { useState, useEffect, useContext, useRef } from 'react';
import { Context } from './Context/AuthContext';
import { useLocation } from "react-router-dom";
import Footer from './components/Footer';
import { Header } from './components/Header';



export default function Layout({ children }) {
    const [isLogin, setIsLogin] = useState(true);
    const location = useLocation()
    const { user, loading, authenticated } = useContext(Context);





    //#region Effects
    useEffect(() => {
        let islogin = location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/" || location.pathname.toLocaleLowerCase().indexOf("printservice") > -1 || location.pathname.toLocaleLowerCase().indexOf("share") > -1;
        setIsLogin(islogin);
    }, [location.pathname]);

    //#endregion Effects


    return (
        <React.Fragment>
            {
                isLogin ? (
                    <div>
                        {children}
                    </div>
                ) : (
                    <div>
                        <Header/>
                        {children} 
                        <Footer />
                    </div>
                ) 
            }
          
          
        </React.Fragment>
    )
}